<template>
  <div class="home">
    <CTabs variant="tabs" :active-tab="activeTab">
      <CTab title="Profile">
        <div class="mt-2">
          <CandidateListUserView :item="candidateProfile" />
        </div>
      </CTab>
      <CTab :title="`Jobs Applied (${getAppliedCount})`">
        <AppliedJobs />
      </CTab>
      <CTab
        :title="`Jobs Withdrawn (${getWithdrawCount})`"
        v-show="getWithdrawCount"
      >
        <WithdrawJobs />
      </CTab>
      <CTab :title="`Favourite Jobs (${getFavoCount})`">
        <FavouriteJobs />
      </CTab>
      <CTab :title="`New Jobs (${getMatchedCount})`">
        <MatchedJobs />
      </CTab>
      <CTab :title="`Messages (${getUniqConversation.length})`">
        <EmailMessages
          v-if="candidateProfile"
          :email="candidateProfile.email"
          :candidate_uid="candidateProfile.candidate_uid"
          :customer_uid="candidateProfile.customer_uid"
          :organisation_id="candidateProfile.organisation_id"
        />
      </CTab>
      <CTab
        :title="`Schedules (${pendingInterview.length})`"
        v-show="pendingInterview.length"
      >
        <Schedules :schedule="getCandidateInterviewSchedules" />
      </CTab>
      <CTab
        :title="`Offer & Onboarding (${checkListJobsCount})`"
        v-show="checkListJobsCount"
      >
        <OfferOnboardingDocument
          :preOfferDocs="preOfferCandidateDocs"
          :offerDocs="getCandidateOffers"
          :licensingDocs="getCandidateOnBoardDocs"
          :visaDocs="visaCandidateDocs"
          :joiningDocs="joiningCandidateDocs"
          :preOfferJobs="preOfferJobs"
          :offerJobs="offerJobs"
          :licensingJobs="licensingJobs"
          :visaJobs="visaJobs"
          :joiningJobs="joiningJobs"
        />
      </CTab>
      <CTab :title="` Courses / Programmes (0)`"></CTab>
      <CTab :title="` Sponsorship (0)`"></CTab>
    </CTabs>
    <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
      <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
    </CToaster>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import CandidateListUserView from "@/containers/CandidateList/CandidateListUserView";
import AppliedJobs from "@/containers/CandidateProfile/AppliedJobs";
import WithdrawJobs from "@/containers/CandidateProfile/WithdrawJobs";
import FavouriteJobs from "@/containers/CandidateProfile/FavouriteJobs";
import MatchedJobs from "@/containers/CandidateProfile/MatchedJobs";
import EmailMessages from "@/containers/CandidateProfile/EmailMessages";
import Schedules from "@/containers/CandidateProfile/Schedules";
import OfferDocuments from "@/containers/CandidateProfile/OfferDocuments";
import OfferOnboardingDocument from "@/containers/CandidateProfile/OfferOnboardingDocument";
import { CONSTANT as CONST } from "@/helpers/helper";

export default {
  components: {
    CandidateListUserView,
    AppliedJobs,
    FavouriteJobs,
    MatchedJobs,
    WithdrawJobs,
    EmailMessages,
    Schedules,
    OfferDocuments,
    OfferOnboardingDocument,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters([
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "candidateProfile",
      "getCustomerId",
      "getAppliedJobsCount",
      "getWithdrawJobsCount",
      "getFavoJobsCount",
      "getMatchedJobsCount",
      "getCandidateEmailMessages",
      "getCandidateOffers",
      "getCandidateInterviewSchedules",
      "getCandidateOnBoardDocs",
      "preOfferCandidateDocs",
      "visaCandidateDocs",
      "joiningCandidateDocs",
      "getCustomerTypeIDFromAccessToken",
    ]),
    pendingInterview() {
      return (
        this.getCandidateInterviewSchedules.filter(
          (v) => v.status_id === 54 && v.sub_status_id === 55
        ) || []
      );
    },
    offerJobIDs() {
      let tempJobs = [];
      this.getCandidateOffers.map((val) => {
        {
          tempJobs.push(val.job_id);
        }
      });
      return new Set(tempJobs);
    },
    onBoardJobIDs() {
      let tempJobs = [];
      this.getCandidateOnBoardDocs.map((val) => {
        {
          tempJobs.push(val.job_id);
        }
      });
      return new Set(tempJobs);
    },
    getFavoCount() {
      return this.getFavoJobsCount;
    },
    getAppliedCount() {
      return this.getAppliedJobsCount;
    },
    getWithdrawCount() {
      return this.getWithdrawJobsCount;
    },
    getMatchedCount() {
      return this.getMatchedJobsCount;
    },
    getUniqConversation() {
      let array = [];
      array = _.uniqBy(this.getCandidateEmailMessages, (v) =>
        [
          v.sender,
          v.subject.split(": ")[1] ? v.subject.split(": ")[1] : v.subject,
        ].join()
      );
      return this.getCandidateEmailMessages.length ? array : [];
    },
    getUnreadMessages() {
      return this.getUniqConversation
        ? _.filter(this.getUniqConversation, (message) => {
            if (message.direction === "O" && !message.read) {
              return message;
            }
          })
        : [];
    },
    preOfferJobs() {
      return (
        _.keys(_.groupBy(this.preOfferCandidateDocs, (doc) => doc.job_id)) || []
      );
    },
    offerJobs() {
      return (
        _.keys(_.groupBy(this.getCandidateOffers, (doc) => doc.job_id)) || []
      );
    },
    licensingJobs() {
      return (
        _.keys(_.groupBy(this.getCandidateOnBoardDocs, (doc) => doc.job_id)) ||
        []
      );
    },
    visaJobs() {
      return (
        _.keys(_.groupBy(this.visaCandidateDocs, (doc) => doc.job_id)) || []
      );
    },
    joiningJobs() {
      return (
        _.keys(_.groupBy(this.joiningCandidateDocs, (doc) => doc.job_id)) || []
      );
    },
    checkListJobsCount() {
      return (
        this.preOfferJobs.length +
          this.offerJobs.length +
          this.licensingJobs.length +
          this.visaJobs.length +
          this.joiningJobs.length || 0
      );
    },
    isEducationalType() {
      return this.candidateProfile?.customer_type_id ===  CONST.CUSTOMER_TYPES.educational_institute || false
    },
    isStudent() {
      return this.candidateProfile?.is_student || false
    }
  },
  methods: {
    ...mapActions([
      "fetchAppliedJobCount",
      "fetchWithdrawJobCount",
      "fetchFavoJobCount",
      "fetchMatchedJobCount",
      "fetchCandidateInterviewSchedule",
      "fetchUploadedDocument",
      "fetchOffersDocsForCandidate",
      "fetchOnBoardDocsForCandidate",
      "fetchDocsForCandidate",
    ]),
  },
  async mounted() {
    const { query } = this.$route;
    const { tab } = query;
    if (tab) this.activeTab = parseInt(tab);
    await this.$store.dispatch("getUserMe", { customer: this.getCustomerId });
    await this.fetchCandidateInterviewSchedule();
    const { customer_uid, candidate_uid, organisation_id } =
      this.candidateProfile;
    let payload = {
      customer_uid,
      candidate_uid,
    };
    if (
      this.getCustomerTypeIDFromAccessToken !=
        CONST.CUSTOMER_TYPE.recruiter_agency &&
      organisation_id
    )
      payload = {
        ...payload,
        organisation_id,
      };
    // this.fetchOffersDocsForCandidate(payload);
    // this.fetchOnBoardDocsForCandidate(payload);
    this.fetchUploadedDocument();
    this.fetchWithdrawJobCount();
    this.fetchDocsForCandidate({
      ...payload,
      action_type__in: ["pre_offer", "offer", "licensing", "visa", "joining"],
    });

    this.fetchMatchedJobCount();
    this.fetchFavoJobCount();
    this.fetchAppliedJobCount();
  },
};
</script>
